/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;
  transition: $global-transition;
  padding: 0 0.15em;

  &:hover {
    text-decoration: none;
    background-color: $color-link;
    color: $color-bg;
  }
}
