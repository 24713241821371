/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 0.875rem;
  padding-top: 1em;
  background-color: #000c;
}

.c-footer .menu {
  margin: 0;
  list-style-type: none;

  a {
    text-decoration: none;
  }
}

.c-footer .menu-footer-menu-container .menu {
  gap: 1em;
  font-size: 1.25rem;

  @include bp(450px) {
    display: flex;
  }
}
