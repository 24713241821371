/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 1em;
  padding-left: 1em;

  @include clearfix();
}
