.u-bg {
  &--yellow {
    background-color: $color-yellow;
    color: $color-black;
  }

  &--black {
    background-color: $color-black;
    color: $color-white;
  }

  &--white {
    background-color: $color-white;
    color: $color-black;
  }

  &--grey {
    background-color: $color-dark-grey;
    color: $color-white;
  }
}
