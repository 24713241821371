/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  margin: 0;
  display: none;

  @include bp(medium) {
    display: flex;
  }

  @include bp-down(medium) {
    &--open {
      background: white;
      display: block;
      position: absolute;
      width: 100%;
      right: 0;
      top: 100%;
    }
  }
}

.c-main-nav__item {
  list-style: none;
  padding: 0;

  @include bp(medium) {
    margin: 0 1em 0 0;
  }
}

.c-main-nav__btn {
  transition: $global-transition;
  background-color: $color-white;
  color: $color-black;

  a:hover {
    opacity: 1;
    background-color: $color-black;
    color: $color-white;
  }
}

.c-main-nav__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-black;
  padding: 1em;

  &:hover {
    color: $color-black;
    opacity: 0.5;
  }

  @include bp(medium) {
    padding: 0.5em;

    .menu-item-has-children & {
      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.25' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
        display: inline-block;
        margin-left: 0.15em;
        height: 1.25em;
        width: 1.25em;
      }
    }
  }
}

.c-main-nav__dropdown {
  display: block;
  flex-direction: column;
  margin: 0;
  padding: 0 2em;
  background: $color-white;

  @include bp(medium) {
    display: none;
    position: absolute;
    padding: 1.5em 1.5em 1em 1.5em;
  }

  .c-main-nav__item:hover & {
    display: flex;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin-bottom: 1em;

  @include bp(medium) {
    border-bottom: 1px solid $color-very-light-grey;
  }

  &:last-of-type {
    border-bottom: none;
    margin: 0;
  }
}

.c-main-nav__sublink {
  color: $color-medium-grey;
  background: $color-white;
  display: block;
  padding-bottom: 1em;
  text-decoration: none;

  @include bp(medium) {
    color: $color-black;
  }

  &:hover {
    background: none !important;
    color: $color-black;
    opacity: 0.5;
  }
}

.current-menu-item .c-main-nav__link {
  @include bp-down(medium) {
    font-weight: bold;
  }

  @include bp(medium) {
    border-bottom: 1px solid $color-black;
  }
}

.current-menu-item.c-main-nav__subitem {
  @include bp-down(medium) {
    font-weight: bold;
  }

  @include bp(medium) {
    border-bottom-color: $color-black;
  }
}

.c-main-nav__toggle {
  background: none;
  appearance: none;
  border: none;
  display: flex;
  cursor: pointer;

  &::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.25' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    display: inline-block;
    margin-left: 0.15em;
    height: 1.25em;
    width: 1.25em;
    transition: $global-transition;
  }

  @include bp(medium) {
    display: none;
  }
}

.c-main-nav--open + .c-main-nav__toggle {
  &::after {
    transform: scale(-100%);
  }
}
