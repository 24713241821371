@charset "UTF-8";
/*
  Project: Stockroom London
  Author: Via Studios
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  color: #fff;
  font-weight: 500;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #000;
  color: #999;
  font-family: proxima-soft, sans-serif;
  font-size: 0.8125rem; /* [1] */ /* 13px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}
@media (min-width: 768px) {
  html {
    font-size: 0.875em; /* 14px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 0.9375em; /* 15px */
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 1em; /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #ffc000;
  text-decoration: underline;
  transition: all 300ms ease-in-out;
  padding: 0 0.15em;
}
a:hover {
  text-decoration: none;
  background-color: #ffc000;
  color: #000;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

.o-layout--wrap {
  flex-wrap: wrap;
}

.o-layout--vcenter {
  align-items: center;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 1em;
  padding-left: 1em;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.c-animation {
  background: black;
  padding: 50px 0;
}

.c-animation__wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

.c-animation__clouds--large {
  background-image: url("../assets/images/cloud-big.svg");
  height: 20px;
  width: 31px;
  position: absolute;
  top: 10px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 24s;
  animation-delay: 6s;
  animation-timing-function: linear;
}

.c-animation__clouds--small {
  background-image: url("../assets/images/cloud-small.svg");
  height: 9px;
  width: 14px;
  position: absolute;
  top: 15px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 28s;
  animation-delay: 2s;
  animation-timing-function: linear;
}

.c-animation__plane {
  background-image: url("../assets/images/plane.svg");
  height: 14px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 20s;
  animation-timing-function: linear;
}

.c-animation__buildings {
  background-image: url("../assets/images/buildings.svg");
  background-position: -1926px 0;
  background-repeat: no-repeat;
  animation: anim-buildings;
  animation-duration: 31s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.c-animation__van {
  height: 35px;
  width: 45px;
  background-image: url("../assets/images/van.svg");
  margin-bottom: 10px;
  position: relative;
  z-index: 9999;
}

.c-animation__pickup {
  background: #ffc000;
  color: black;
  border-radius: 1em;
  line-height: 1em;
  font-size: 20px;
  position: absolute;
  bottom: 35px;
  padding: 0.5em 0.85em;
  opacity: 0;
}

.c-animation__pickup.animating {
  animation: anim-pickup;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes anim-lr {
  from {
    right: -31px;
  }
  to {
    right: 1280px;
  }
}
@keyframes anim-buildings {
  from {
    background-position: 14px 0;
  }
  to {
    background-position: -1923px 0;
  }
}
@keyframes anim-pickup {
  0% {
    opacity: 0;
    bottom: 35px;
  }
  3% {
    transform: scale(0.95);
  }
  7% {
    transform: scale(1.1);
  }
  10% {
    opacity: 1;
    bottom: 60px;
    transform: scale(1);
  }
  85% {
    opacity: 1;
    bottom: 60px;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    bottom: 73px;
    transform: scale(0.9);
  }
}
/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #ffc000;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none; /* [4] */
  color: #fff;
}
.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #ffc000;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 0.875rem;
  padding-top: 1em;
  background-color: rgba(0, 0, 0, 0.8);
}

.c-footer .menu {
  margin: 0;
  list-style-type: none;
}
.c-footer .menu a {
  text-decoration: none;
}

.c-footer .menu-footer-menu-container .menu {
  gap: 1em;
  font-size: 1.25rem;
}
@media (min-width: 450px) {
  .c-footer .menu-footer-menu-container .menu {
    display: flex;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background: #ffc000;
  padding: 1em 0;
  position: sticky;
  z-index: 1000;
  top: 0;
}
.c-header__logo {
  display: inline-block;
  width: 30%;
  max-width: 229px;
  min-width: 170px;
}
.c-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  margin: 0;
  display: none;
}
@media (min-width: 768px) {
  .c-main-nav {
    display: flex;
  }
}
@media (max-width: 767.98px) {
  .c-main-nav--open {
    background: white;
    display: block;
    position: absolute;
    width: 100%;
    right: 0;
    top: 100%;
  }
}

.c-main-nav__item {
  list-style: none;
  padding: 0;
}
@media (min-width: 768px) {
  .c-main-nav__item {
    margin: 0 1em 0 0;
  }
}

.c-main-nav__btn {
  transition: all 300ms ease-in-out;
  background-color: #fff;
  color: #000;
}
.c-main-nav__btn a:hover {
  opacity: 1;
  background-color: #000;
  color: #fff;
}

.c-main-nav__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  padding: 1em;
}
.c-main-nav__link:hover {
  color: #000;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .c-main-nav__link {
    padding: 0.5em;
  }
  .menu-item-has-children .c-main-nav__link::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.25' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    display: inline-block;
    margin-left: 0.15em;
    height: 1.25em;
    width: 1.25em;
  }
}

.c-main-nav__dropdown {
  display: block;
  flex-direction: column;
  margin: 0;
  padding: 0 2em;
  background: #fff;
}
@media (min-width: 768px) {
  .c-main-nav__dropdown {
    display: none;
    position: absolute;
    padding: 1.5em 1.5em 1em 1.5em;
  }
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__subitem {
  list-style: none;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .c-main-nav__subitem {
    border-bottom: 1px solid #ccc;
  }
}
.c-main-nav__subitem:last-of-type {
  border-bottom: none;
  margin: 0;
}

.c-main-nav__sublink {
  color: #666;
  background: #fff;
  display: block;
  padding-bottom: 1em;
  text-decoration: none;
}
@media (min-width: 768px) {
  .c-main-nav__sublink {
    color: #000;
  }
}
.c-main-nav__sublink:hover {
  background: none !important;
  color: #000;
  opacity: 0.5;
}

@media (max-width: 767.98px) {
  .current-menu-item .c-main-nav__link {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  .current-menu-item .c-main-nav__link {
    border-bottom: 1px solid #000;
  }
}

@media (max-width: 767.98px) {
  .current-menu-item.c-main-nav__subitem {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  .current-menu-item.c-main-nav__subitem {
    border-bottom-color: #000;
  }
}

.c-main-nav__toggle {
  background: none;
  appearance: none;
  border: none;
  display: flex;
  cursor: pointer;
}
.c-main-nav__toggle::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.25' stroke-linecap='square' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  display: inline-block;
  margin-left: 0.15em;
  height: 1.25em;
  width: 1.25em;
  transition: all 300ms ease-in-out;
}
@media (min-width: 768px) {
  .c-main-nav__toggle {
    display: none;
  }
}

.c-main-nav--open + .c-main-nav__toggle::after {
  transform: scale(-100%);
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-slideshow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-slideshow__item {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: fade 12s infinite;
  object-fit: cover;
}
.c-slideshow__item:nth-child(1) {
  animation-delay: 0s;
}
.c-slideshow__item:nth-child(2) {
  animation-delay: 3s;
}
.c-slideshow__item:nth-child(3) {
  animation-delay: 6s;
}
.c-slideshow__item:nth-child(4) {
  animation-delay: 9s;
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  20%, 80% {
    opacity: 1;
  }
}
/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-bg--yellow {
  background-color: #ffc000;
  color: #000;
}
.u-bg--black {
  background-color: #000;
  color: #fff;
}
.u-bg--white {
  background-color: #fff;
  color: #000;
}
.u-bg--grey {
  background-color: #333;
  color: #fff;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-max--600 {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1em;
  padding-right: 1em;
}
.u-max--800 {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.u-pad--all {
  padding: 2em;
}
@media (min-width: 768px) {
  .u-pad--all {
    padding: 4em;
  }
}

.u-margin--bottom {
  margin-bottom: 2em;
}

.wp-block-columns {
  gap: 0 !important;
}