.c-animation {
  background: black;
  padding: 50px 0;
}

.c-animation__wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

.c-animation__clouds--large {
  background-image: url('../assets/images/cloud-big.svg');
  height: 20px;
  width: 31px;
  position: absolute;
  top: 10px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 24s;
  animation-delay: 6s;
  animation-timing-function: linear;
}

.c-animation__clouds--small {
  background-image: url('../assets/images/cloud-small.svg');
  height: 9px;
  width: 14px;
  position: absolute;
  top: 15px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 28s;
  animation-delay: 2s;
  animation-timing-function: linear;
}

.c-animation__plane {
  background-image: url('../assets/images/plane.svg');
  height: 14px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 1280px;
  animation: anim-lr;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 20s;
  animation-timing-function: linear;
}

.c-animation__buildings {
  background-image: url('../assets/images/buildings.svg');
  background-position: -1926px 0;
  background-repeat: no-repeat;
  animation: anim-buildings;
  animation-duration: 31s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.c-animation__van {
  height: 35px;
  width: 45px;
  background-image: url('../assets/images/van.svg');
  margin-bottom: 10px;
  position: relative;
  z-index: 9999;
}

.c-animation__pickup {
  background: #ffc000;
  color: black;
  border-radius: 1em;
  line-height: 1em;
  font-size: 20px;
  position: absolute;
  bottom: 35px;
  padding: 0.5em 0.85em;
  opacity: 0;
}

.c-animation__pickup.animating {
  animation: anim-pickup;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes anim-lr {
  from {
    right: -31px;
  }

  to {
    right: 1280px;
  }
}

@keyframes anim-buildings {
  from {
    background-position: 14px 0;
  }

  to {
    background-position: -1923px 0;
  }
}

@keyframes anim-pickup {
  0% {
    opacity: 0;
    bottom: 35px;
  }

  3% {
    transform: scale(0.95);
  }

  7% {
    transform: scale(1.1);
  }

  10% {
    opacity: 1;
    bottom: 60px;
    transform: scale(1);
  }

  85% {
    opacity: 1;
    bottom: 60px;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    bottom: 73px;
    transform: scale(0.9);
  }
}
