.u-pad {
  &--all {
    padding: 2em;

    @include bp(medium) {
      padding: 4em;
    }
  }
}

.u-margin {
  &--bottom {
    margin-bottom: 2em;
  }
}
