.c-slideshow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-slideshow__item {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: fade 12s infinite;
  object-fit: cover;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 3s;
  }

  &:nth-child(3) {
    animation-delay: 6s;
  }

  &:nth-child(4) {
    animation-delay: 9s;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  20%,
  80% {
    opacity: 1;
  }
}
