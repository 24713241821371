/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: $color-yellow;
  padding: 1em 0;
  position: sticky;
  z-index: 1000;
  top: 0;

  &__logo {
    display: inline-block;
    width: 30%;
    max-width: 229px;
    min-width: 170px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
