.u-max {
  &--600 {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  &--800 {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1em;
    padding-right: 1em;
  }
}
